import { LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  GoToEditPassword,
  GoToEditUser,
  GoToLogin,
  GoToSignUp
} from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import {
  AUTH_UI_PATH_ELEMENTS,
  AUTH_UI_PATHS
} from 'projects/medtoday/src/app/auth-ui/definitions/auth-ui-navigation.definitions';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { environment, imagePaths } from '../../../../../../medtoday/src/environments/environment';
import { ObservableComponent } from '../../../../../shared/components/observable/observable.component';
import { LoginRedirect, Logout } from '../../../../auth/actions/auth.actions';
import { getUserAttributes, isLoggedIn } from '../../../../auth/selectors/auth.selectors';
import {
  getCongressMetaData,
  getFirstMenuPage,
  getSecondMenuPage,
  getThirdMenuPage
} from '../../../../data/selectors/data-api.selectors';
import {
  GoToCMEPage,
  GoToCongressList,
  GoToDisclaimer,
  GoToImprint,
  GoToMedicalEducationPage,
  GoToMyCme,
  GoToNetiquette,
  GoToSearch,
  GoToTermsOfService
} from '../../../../router/actions/main-navigation.actions';
import {
  MAIN_NAVIGATION_PATH_ELEMENTS,
  MAIN_NAVIGATION_PATHS
} from '../../../../router/definitions/main-navigation.definitions';
import { getCongressSlugRouterParam, getLastUrlSegment } from '../../../../router/selectors/router.selectors';
import { BaseAppState } from '../../../../store/reducers';
import { NavBarService } from '../../services/nav-bar.service';
import { Page } from 'medtoday-models-library';
import { SubPage } from 'medtoday-models-library/lib/models/sub-page.model';
declare var $;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [NavBarService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarComponent extends ObservableComponent implements OnInit {
  LOGIN_PATH = AUTH_UI_PATH_ELEMENTS.root.loginElement;
  SIGN_UP_PATH = AUTH_UI_PATH_ELEMENTS.root.signUpElement;
  CONGRESSES_PATH = MAIN_NAVIGATION_PATHS.root.congresses;

  theming = '';
  congressSlug = '';
  logoPathUrl = imagePaths.logos;
  imagePathUrl = imagePaths.images;
  s3BucketUrl = environment.s3BucketUrl;
  medboardsUrl = environment.medboardsUrl;

  twitterProfileUrl = environment.twitterProfileUrl;
  linkedInProfileUrl = environment.linkedInProfileUrl;
  masterclassUrl = 'https://masterclass.medtoday.de/';

  userAttributes$ = this.store.select(getUserAttributes);
  congressMetaData$ = this.store.select(getCongressMetaData);
  firstMenuPage$ = this.store.select(getFirstMenuPage);
  secondMenuPage$ = this.store.select(getSecondMenuPage);
  thirdMenuPage$ = this.store.select(getThirdMenuPage);
  isLoggedIn$ = this.store.select(isLoggedIn);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  lastUrlSegment$ = this.store.select(getLastUrlSegment);
  currentLanguage = this.translateService.currentLang;
  showNavbarBackground = false;

  get shouldShowNavBarBackground() {
    return this.showNavbarBackground || this.navBarService.navBarOpenedFromMobile;
  }

  constructor(
    private router: Router,
    protected store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string,
    private translateService: TranslateService,
    @Inject('domain') public domain: string,
    private url: LocationStrategy,
    public navBarService: NavBarService,
    protected renderer: Renderer2
  ) {
    super();
  }

  @HostListener('window:scroll', ['$event'])
  updateScrollPos() {
    if (window.pageYOffset > 100) {
      this.showNavbarBackground = true;
    } else {
      this.showNavbarBackground = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.navBarService.navBarOpenedFromMobile && event?.target.innerWidth > 992) {
      this.navBarService.handleToggleNavBar();
      document.getElementById('navbarToggleExternalContent')?.classList.remove('show');
      document.getElementById('navbarButton')?.classList.add('collapsed');
      document.querySelector('main')?.removeAttribute('style');
      document.querySelector('app-footer')?.removeAttribute('style');
    }
  }

  ngOnInit() {
    this.observeCongressSlug();
    this.observePages();
    this.initOutsideClickChecks();
  }

  getActiveLinksCount(page: Page) {
    return page.subPages?.filter((subPage: SubPage) => subPage.active)?.length;
  }

  getOnlyActiveLink(page: Page) {
    return page.subPages?.filter((subPage: SubPage) => subPage.active);
  }

  observePages() {
    this.lastUrlSegment$
      .pipe(
        map((segment: string) => {
          if (
            segment === MAIN_NAVIGATION_PATH_ELEMENTS.root.termsOfServiceElement ||
            segment === MAIN_NAVIGATION_PATH_ELEMENTS.root.disclaimerElement ||
            segment === MAIN_NAVIGATION_PATH_ELEMENTS.root.imprintElement ||
            segment === MAIN_NAVIGATION_PATH_ELEMENTS.root.netiquette
          ) {
            this.theming = 'dark';
          } else {
            this.theming = 'light';
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeCongressSlug() {
    this.congressSlug$
      .pipe(distinctUntilChanged(), takeUntil(this.ngDestroy$))
      .subscribe((slug: string) => (this.congressSlug = slug));
  }

  inCongress() {
    if (this.url.path().includes('/congresses') || this.url.path().includes('/med-ed')) {
      return true;
    } else return false;
  }

  handleHomeClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToCongressList());
  }

  handleMedEdClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToMedicalEducationPage());
  }

  handleLogoutClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new Logout());
  }

  handleSignUpClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new LoginRedirect(this.router.url));
    this.router.createUrlTree([AUTH_UI_PATHS.root.login]);
    this.store.dispatch(new GoToSignUp());
  }

  handleLoginClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToLogin());
  }

  handleEditPasswordClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToEditPassword());
  }

  handleEditUserClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToEditUser());
  }

  handleGoToImprintClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToImprint());
  }

  handleDataProtectionClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToTermsOfService());
  }

  handleDisclaimerClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToDisclaimer());
  }

  handleNetiquetteClick() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToNetiquette());
  }

  handleCMEGlobalPage() {
    this.navBarService.toggleNavCongress();
    this.store.dispatch(new GoToCMEPage());
  }

  handleGoToSearchClick() {
    this.store.dispatch(new GoToSearch());
  }

  handleMyCmeClick() {
    this.store.dispatch(new GoToMyCme());
  }

  handleCongressesMenuClick() {
    if (!this.showNavbarBackground) {
      this.showNavbarBackground = true;
    } else {
      this.updateScrollPos();
    }
  }

  handleGoToPartnerNetworkClick() {
    this.navBarService.toggleNavCongress();
    this.router.navigate(['partner-network'])
  }

  protected initOutsideClickChecks() {
    // checks if we click outside of the menu if yes we want to close it
    const topicCategoriesElement = $('#congressDropdown');
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        topicCategoriesElement &&
        topicCategoriesElement.hasClass('show') &&
        !topicCategoriesElement.is(e.target) &&
        topicCategoriesElement.has(e.target).length === 0
      ) {
        $('#congressDropdown').collapse('hide');
      }
    });
  }
}
